import React from 'react'
import Link from '../utils/link'

const Footer = () => {

  return (
    <>
      <footer className='footer'>
        <div className='footer__inner'>
          <p>© 2023 Supply Chain Cover</p>
          <p>Design by <Link to='https://atollon.com.au/'>Atollon</Link></p>
        </div>
      </footer>
    </>
  )
}

export default Footer