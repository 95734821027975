import React from 'react'
import Link from '../utils/link'
import { Logo, Plus } from './icons'

const Header = () => {

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) { anchorElement.scrollIntoView({ behavior: 'smooth' }) }
    }
  } 

  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div className='header__content'>
            <Link className='header__logo' to='/'>
              <Logo />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><button onClick={() => scrollToAnchor('approach')}><span>Our Company</span></button></li>
                <li><button onClick={() => scrollToAnchor('services')}><span>Our Services</span></button></li>
                <li><button onClick={() => scrollToAnchor('values')}><span>Our Values</span></button></li>
                <li><button onClick={() => scrollToAnchor('team')}><span>Our Team</span></button></li>
                <li><button onClick={() => scrollToAnchor('partners')}><span>Our Partners</span></button></li>
              </ul>
              <ul>
                <li><button onClick={() => scrollToAnchor('contact')}><span>Contact</span></button></li>
                <li><Link to='https://www.linkedin.com/company/supply-chain-cover/'><span>LinkedIn</span></Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header