import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location }) => {
  const data = layoutQuery()
  const [active, setActive] = React.useState(false)

  // setTimeout of 1s and change active to true
  React.useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 750)
    setTimeout(() => {
      setActive(true)
      document.querySelector('.company').classList.add('sal-animate')
    }, 1500)
  }, [])

  return (
    <>
      <Header location={location} data={data} />
      <main className={active ? 'sal-animate' : ''}>{children}</main>
      <Footer data={data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
